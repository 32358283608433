<script setup lang="ts">
import { PageDocument, type PageQuery } from '~/generated/operations'

definePageMeta({
	layout: 'homepage',
	layoutTransition: {
		onAfterEnter: () => console.log('after enter homepage'),
		onAfterLeave: () => console.log('after leave homepage'),
	},
})

const { data } = await useCachedQuery<PageQuery>({
	key: 'pages:homepage',
	query: PageDocument,
	variables: {
		slug: 'homepage',
	},
})

const page = data.value?.entry as PageQueryResult
provide('page', page)

const description = page.seoDescription ?? null

useSeoMeta({
	ogTitle: page.seoTitle ?? page.title,
	description: description,
	ogDescription: description,
	ogUrl: page.url,
	ogLocale: 'nl_NL',
	ogSiteName: 'NEMOKennislink',
	ogType: 'website',
	ogImage:
		page.header_image[0] &&
		page.header_image[0].optimizedImages?.srcUrls[2] &&
		page.header_image[0].optimizedImages?.srcUrls[2][1]
			? page.header_image[0].optimizedImages?.srcUrls[2][1]
			: null,
	twitterTitle: page.seoTitle ?? page.title,
	twitterDescription: description,
	twitterSite: '@NEMOkennislink',
	twitterCard: 'summary_large_image',
})


useHead({
	link: [
		{
			rel: 'canonical',
			href: 'https://www.nemokennislink.nl/',
		},
	],
})


</script>

<template>
	<PagesComponentPage />
</template>
